
.awsui-util-label {
    display: flex;
    align-items: center;
}

.awsui-util-status-positive {
    display: flex;
}

.awsui-button {
    display: flex;
}

.page-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.awsui-app-layout__content--scrollable {
    padding-top: 0 !important;
}