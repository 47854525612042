.infoTable {
    margin-bottom: 50px;
}

.brokerIdHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.scrollUpButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    zIndex: 1000;
}

.inlineButtonContainer button {
    padding: 0 !important;
    padding-left: 0.5rem !important;
}

.copymarkdown-button button {
    display: flex !important;
}