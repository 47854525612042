.selected-log-item {
  font-size: 13px;
  font-family: monaco, menlo, consolas, "courier prime", courier, "courier new", monospace;
  margin: 8px 0 8px;
  white-space: normal;
  cursor: pointer;
}

.unselected-log-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: -0.05em;
  word-spacing: -0.05em;
  font-family: monaco, menlo, consolas, "courier prime", courier, "courier new", monospace;
  font-size: 12px;
  border-bottom: 0;
  cursor: pointer;
}

.logsDetailsTable .awsui-table-container > table {
  width: 454px;
  table-layout: fixed;
  white-space: nowrap;
  border-spacing: 0;
}

.logsDetailsTable .awsui-table-container {
  overflow-x: auto;
  position: relative;
  width: 100%;
}

.awsui awsui-table .awsui-table-container > table thead > tr:nth-child(1) > [data-awsui-column-id=expandColumn] {
  width: 15px !important;
  min-width: 10px !important;
}

.awsui awsui-table .awsui-table-container > table thead > tr:nth-child(1) > [data-awsui-column-id=timestamp] {
  width: 215px;
  max-width: 215px !important;
}