.infoTableDiv {
    position: relative;
    white-space: pre;
}

.infoTableDiv > .exportBtn {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
}

.infoTableDiv > .hideExportBtn {
    display: none;
}

.awsui-modal-body {
    white-space: pre;
}
