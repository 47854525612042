.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    /* padding: 0 30px; */
    /* height: 50px; */
    background: #232f3e;
    list-style: none;
    /* left: -30px; */
    width: 100%;
    gap: 8px;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

.menu-wrapper {
    padding-left: 20px;
    background: #232f3e;
    padding-right: 20px;
    display: flex;
}

.menu > .searchBar {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-items: center;
    justify-content: center;
    gap: 8px;
}

.menu > #hidden {
    display: none;
}

.menu > .searchBar > .dropDown-region {
}

.menu > .searchBar > .dropDown-domain {
    margin-left: 0px;
}

.menu > .searchBar > .search {
    width: 400px;
}

.menu > .searchBar > .searchButton {
    /* position: absolute; */
}

.menu > .title {
    font-weight: bold;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: medium;
}

.menu > .user {
    font-weight: bold;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: medium;
}