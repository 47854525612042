.selectedTab button {
    background-color: transparent !important;
    border: 0 !important;
    color: #ec7211 !important;
    border-right: 1px solid #aab7b8 !important;
}

.unselectedTab button {
    border: 0 !important;
    background-color: transparent !important;
    border-right: 1px solid #aab7b8 !important;
}

.currentInstanceCheckbox {
    font-weight: bold !important;
}

.terminatedInstanceCheckbox {
    color: gray !important;
}