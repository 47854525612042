#mainPage-describeBroker {
    margin-top: 75px;
}

.searchBar > .searchBar-item {
    display: inline-block;
    margin: 0 5px;
}

.region {
    width: 185px;
}

.dashboard {
    width: 250px;
}

.searchBar {
    text-align: center;
}

.mainPage{
    padding: 20% 0;
}

.awsui .awsui-app-layout__content {
    padding: 0 !important;
}